body {
  margin: 0;
}

@media (min-width: 48em) {
  html {
    font-size: 16px;
  }

  body:not(.sidebar-hidden) {
    body {
      display: table;
    }

    div.content.wrapper,
    header.sidebar {
      display: table-cell;
    }
    header.sidebar {
      width: 15rem;
    }

    .menu-icon {
      display: none;
    }
  }
}

@media (max-width: 47.9em) {
  .menu-icon {
    display: block;
  }

  .sidebar:focus .sidebar-main,
  .sidebar:hover .sidebar-main {
    display: block;
  }
  .sidebar-main {
    display: none;
  }
}

.sidebar-hidden {
  .menu-icon {
    display: block;
  }

  .sidebar:focus .sidebar-main,
  .sidebar:hover .sidebar-main {
    display: block;
  }
  .sidebar-main {
    display: none;
  }
}

@media (min-width: 64em) {
}

.menu-icon {
  cursor: pointer;
  float: right;
}

.menu-icon div {
  width: 2.188rem;
  height: 0.3125rem;
  background-color: #bbb;
  margin: 0.375rem 0;
}

/*
 * Sidebar
 *
 * Flexible banner for housing site name, intro, and "footer" content. Starts
 * out above content in mobile and later moves to the side with wider viewports.
 */

header.sidebar {
  text-align: left;
  background-color: #202020;
}

.sidebar {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    margin-top: 7px;
    padding-bottom: 7px;
  }

  li:not(:last-child) {
    border-bottom: 1px dotted #666;
  }

  a {
    text-decoration: none;
  }
}

.sidebar-nav.sub {
  margin-top: -1rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-top: 0.25rem;
  background-color: #404040;

  .sidebar-nav-item {
    font-size: 0.9rem;
  }
}

.sidebar-main > div,
.sidebar-main > nav {
  padding: 0rem 1rem;
}

.sidebar,
.sidebar a,
.sidebar-title h1 {
  color: #eee;
}

.sidebar-nav.main {
  background-color: #2a2a2a;
  padding: 1rem;
}

.sidebar-me p {
  font-size: 0.7rem;
}

.sidebar-me a {
  text-decoration: underline;
}

.sidebar-copy {
  text-align: center;
  font-size: 0.6rem;
}

/* title section */
.sidebar-title h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Palatino, serif;
  font-size: 1.75rem;
}

.sidebar-title {
  padding: 1rem !important;

  .lead {
    font-family: Tahoma, sans-serif;
    font-size: 0.7rem;
    margin: 0;
    margin-top: -0.5rem;
    padding-left: 4.2rem;
    color: #999;
  }
}

.sidebar-nav h3,
.main .sidebar-nav-item {
  display: block;
  line-height: 1.75;
  font-size: 1.25rem;
}

.sidebar-nav-item {
  display: block;
}

a.sidebar-nav-item:hover,
a.sidebar-nav-item:focus {
  text-decoration: underline;
}
.sidebar-nav-item.active {
  font-weight: bold;
}

html,
body {
  font-family: MiloSerifPro, Palatino, serif;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  text-rendering: optimizeLegibility;
}

article.post {
  figcaption {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    text-align: center;
  }
}

article .tangent {
  padding-left: 1ex;
  border-left: 1ex solid #fcfcfc;
}

.fignum {
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

.content.wrapper {
  padding: 1rem 2rem;
  max-width: 37rem;

  p,
  li {
    line-height: 1.3;
    font-weight: 400;
    font-style: normal;
  }

  li {
    padding-bottom: 0;
  }

  li:not(:last-child) {
    padding-bottom: 0.5rem;
  }
}

.bibtitle {
  font-size: 1.15rem;
}

.bibseries {
  font-weight: bold;
}

.bibtex pre {
  white-space: pre-wrap;
  font-size: 0.7rem;
}

.bibtex:focus pre,
.bibtex:hover pre {
  display: block;
}
.bibtex pre {
  display: none;
}
.biblink {
  cursor: pointer;
  text-decoration: underline;
}

.post-meta {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: table;
  width: 100%;

  time,
  .next {
    width: 100%;
    text-align: right;
  }

  .post-categories,
  time {
    display: table-cell;
    padding-bottom: 0.5rem;
    width: 49%;
  }

  .prev,
  .next {
    display: table-cell;
    width: 50%;
  }

  .prev {
    padding-right: 2rem;
  }
  .next {
    padding-left: 2rem;
  }
}

.gutter .lineno {
  color: #ccc;
  padding-right: 1rem;
  line-height: 1.18rem;
  font-size: 0.8rem;
  vertical-align: bottom;
}

.content {
  a {
    color: #268bd2;
  }
  a:visited {
    color: #d2268b;
  }
  a:hover {
    color: #d26d26;
  }

  blockquote {
    border-left: 0.5rem solid #eaeaea;
    padding-left: 1rem;
    margin-left: 1rem;
  }
}

.top-right-info-box {
  margin-right: 0.5em;
  margin-left: 0.5em;
  width: 10em;
  font-size: 65%;
  margin-bottom: 1em;
  text-align: left;
  float: right;
  display: block;
}

figure {
  margin-left: 0;
  margin-right: 0;
}
figure img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
figure.full img {
  max-width: 100%;
}
figure.two-thirds img {
  max-width: 66%;
}

.sidenote {
  position: absolute;
  padding-left: 47em;
  margin-top: -1.7em;
  width: 10em;
  font-size: 80%;
  text-align: left;
  display: block;
  z-index: -1;
  text-indent: -0.7em;
}

.sidenote sup {
  padding-right: 0.2em;
}

@media print {
  .sidebar,
  .sidebar-main,
  .older-posts,
  .post-meta {
    display: none;
  }

  html {
    font-size: 11pt;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }

  figure {
    page-break-inside: avoid;
  }
  figcaption {
    page-break-before: avoid;
  }
}
